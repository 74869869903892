import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Checkup from "../../components/checkup"
import { Link } from "gatsby"

import OtherCaseStudy from "../../components/other-case"

import melisa from "../../assets/img/teams/18.jpg"
import dilara from "../../assets/img/teams/36.png"  
import seher from "../../assets/img/teams/04.jpg"
import sedef from "../../assets/img/teams/19.jpg"
import bengu from "../../assets/img/teams/17.jpg"
import serife from "../../assets/img/teams/20.jpg"
import aysima from "../../assets/img/teams/35.jpg"


import rotamizmilli from "../../assets/img/rotamizmilli.png"
import onizleme from "../../assets/img/onizleme.png";
import laptop from "../../assets/img/stm-laptop.png";
import stmseo from "../../assets/img/stm-seo.png";

const ReferancesDetail = () => (
    <Layout>
        <SEO title="STM Başarı Hikayesi" />
        <div className="referances-detail py60 container">
            <div className="col-md-6 detail-left">
                <b>BAŞARI HİKAYESİ</b>
                <h1>STM Savunma Teknolojileri, Mühendislik ve Ticaret A.Ş. </h1>
                <p style={{ maxWidth: 420}}>STM Savunma Teknolojileri, Mühendislik ve Ticaret A.Ş. 1991 yılında Türkiye’nin ulusal güvenliği için yüksek teknoloji gerektiren alanlarda T.C. Cumhurbaşkanlığı Savunma Sanayii Başkanlığı ve Türk Silahlı Kuvvetleri’ne proje yönetimi, sistem mühendisliği, teknoloji transferi, teknik ve lojistik destek ile danışmanlık hizmetleri sağlamak üzere kuruldu. </p>
                <p style={{ maxWidth: 420 }}>Flatart olarak STM markasının marka bilinirliğinin güçlü şekilde aktarılması için 360° dijital pazarlama hizmeti vermekteyiz.</p>
                <p style={{ maxWidth: 420 }}>Kullanıcıların içgörülerini ve markanın dijital verilerini okuyarak dijital pazarlama stratejisi üretmek ve uygulamak işimizin temelini oluşturmakta.</p>
                
                </div>
            <div className="col-md-6 referances-boxs">
                <div className="boxs">
                    <div className="box">
                        <span>+%22</span>
                        <p>Organik Trafik Artışı</p>
                    </div> 
                    <div className="box">
                        <span>+%27.7</span>
                        <p>Takipçi Kazanımı</p>
                    </div>
                </div>
                <div className="provided">
                    <h3>Verilen Hizmetler</h3>
                    <div className="provided-box">
                        <div className="box">
                            <Link to="/ui-ux-tasarim/"></Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 76 76">
                                <g id="Icons-_-Design-_-Sketch" data-name="Icons-/-Design-/-Sketch" transform="translate(0.015 -0.307)">
                                    <rect id="bound" width="76" height="76" transform="translate(-0.015 0.307)" fill="none" />
                                    <path id="Path-48" d="M13.728,3H58.276L71,18.91H1Z" transform="translate(2.182 6.546)" fill-rule="evenodd" opacity="0.3" />
                                    <path id="Path-48-Copy" d="M71,8,36,46.184,1,8Z" transform="translate(2.182 17.456)" fill-rule="evenodd" />
                                </g>
                            </svg>
                            Dijital Danışmanlık
                        </div>
                        <div className="box">
                            <svg style={{ marginLeft: -7 }} xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 79 80">
                                <g id="Icons-_-Shopping-_-Chart-line_1" data-name="Icons-/-Shopping-/-Chart-line#1" transform="translate(-0.462 0.373)">
                                    <rect id="bound" width="79" height="80" transform="translate(0.462 -0.373)" fill="none" />
                                    <path id="Path-95" d="M9.6,55.773H59.071a3.3,3.3,0,0,1,0,6.6H6.3a3.3,3.3,0,0,1-3.3-3.3V6.3a3.3,3.3,0,0,1,6.6,0Z" transform="translate(6.895 7.094)" fill="#000000" />
                                    <path id="Path-97" d="M12.7,33.493a3.3,3.3,0,0,1-4.812-4.512L20.261,15.788a3.3,3.3,0,0,1,4.547-.253l9.762,8.33L47.289,7.755a3.3,3.3,0,1,1,5.178,4.088l-14.842,18.8a3.3,3.3,0,0,1-4.73.465L22.92,22.6Z" transform="translate(16.088 15.138)" fill="#000000" opacity="0.3" />
                                </g>
                            </svg>
                            SEO
                        </div>
                        <div className="box">
                            <Link to="/digital-ads/"></Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 76 76">
                                <g id="Icons-_-Shopping-_-Cart_1" data-name="Icons-/-Shopping-/-Cart#1" transform="translate(0.452 0.373)">
                                    <rect id="bound" width="76" height="76" transform="translate(-0.452 -0.373)" fill="none" />
                                    <path id="Combined-Shape" d="M52.453,31.233l-2.2,13.227-.008.046a3.161,3.161,0,0,1-3.675,2.544L10.63,40.517a3.161,3.161,0,0,1-2.474-2.241L2.389,18.088a3.161,3.161,0,0,1,3.039-4.029H48.813l1.938-8.306a3.729,3.729,0,0,1,3.632-2.882h6.527a3.729,3.729,0,1,1,0,7.459H57.342Z" transform="translate(4.899 6.203)" fill-rule="evenodd" opacity="0.3" />
                                    <path id="Combined-Shape-2" data-name="Combined-Shape" d="M9.741,27.482a4.741,4.741,0,1,1,4.741-4.741A4.741,4.741,0,0,1,9.741,27.482Zm28.447,0a4.741,4.741,0,1,1,4.741-4.741A4.741,4.741,0,0,1,38.188,27.482Z" transform="translate(10.804 38.894)" fill-rule="evenodd" />
                                </g>
                            </svg>
                            Digital Ads
                        </div>
                        <div className="box">
                            <Link to="/sosyal-medya/"></Link>
                            <svg id="instagram-logo" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 61.312 61.312">
                                <path id="Path_36346" data-name="Path 36346" d="M44.392,0H16.92A16.939,16.939,0,0,0,0,16.92V44.392a16.939,16.939,0,0,0,16.92,16.92H44.392a16.939,16.939,0,0,0,16.92-16.92V16.92A16.939,16.939,0,0,0,44.392,0ZM55.872,44.392a11.493,11.493,0,0,1-11.48,11.48H16.92A11.493,11.493,0,0,1,5.44,44.392V16.92A11.493,11.493,0,0,1,16.92,5.44H44.392a11.493,11.493,0,0,1,11.48,11.48V44.392Z" />
                                <path id="Path_36347" data-name="Path 36347" d="M56.767,40.97a15.8,15.8,0,1,0,15.8,15.8A15.816,15.816,0,0,0,56.767,40.97Zm0,26.156A10.358,10.358,0,1,1,67.125,56.768,10.37,10.37,0,0,1,56.767,67.126Z" transform="translate(-26.111 -26.112)" />
                                <path id="Path_36348" data-name="Path 36348" d="M122.91,28.251a3.987,3.987,0,1,0,2.821,1.168A4,4,0,0,0,122.91,28.251Z" transform="translate(-75.793 -18.005)" />
                            </svg>
                            Sosyal Medya
                        </div>
                    </div>
                </div>
                <div className="project-details">
                    <h3>Künye</h3>
                    <div className="project-details_list">
                        <ul>
                            <li>Marka Stratejisti:</li>
                            <li>Marka Yöneticisi:</li>
                            <li>Sosyal Medya Yöneticisi:</li>
                            <li>Sosyal Medya Danışmanı:</li>
                            <li>SEO:</li>
                            <li>Digital Ads:</li>
                            <li>Metin Yazarı:</li>
                        </ul>
                        <ul>
                            <li><img src={melisa} />Melisa Akınsal</li>
                            <li><img src={dilara} />Dilara Karakuş</li>
                            <li><img src={seher} alt="Şerife Aytekin" />Seher Taylan</li>
                            <li><img src={sedef}  alt="Alaattin Emre Elbeyoğlu" />Sedef Karaman</li>
                            <li><img src={bengu} />Bengü Dinçer</li>
                            <li><img src={serife} />Şerife Aytekin</li>
                            <li><img src={aysima} />Aysima Bakırcılar</li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
        <div className="container">
            <div className="content-section content row" >
                <div className="col-md-6">
                    <img  src={rotamizmilli} alt="iki laptop bilgisayar ekranında tcdd taşımacılık web sitesi görüntüleniyor" />
                </div>
                <div className="col-md-6">
                    <p className="small" style={{ maxWidth: 480, color:"#ea4f6a", textAlign:"right" }} >Başarı Hikayesi</p>
                    <h3 style={{ maxWidth: 480, fontSize: 25, textAlign:"right" }}><b>Etkinlik Dönemine Özel  Dijital Aksiyonlar</b></h3>
                    <p style={{ maxWidth: 480, textAlign:"right" }} >Etkinlik dönemlerinde projelerin ve faaliyetlerin hedef kitleye ulaşabilmesi için etkinlik stratejisi kapsamında içerik pazarlama, sosyal medya içerikleri, dijital reklam aksiyonları ve trend öneriler ışığında proje kurguları gerçekleştirdik.</p>
                    <h3 style={{ maxWidth: 480, fontSize: 23, textAlign:"right"}}>  TCG İstanbul Fırkateyni’nin Denize İndirme Töreni dijital iletişimi kapsamında yürüttüğümüz <b>#rotamızmilli</b> hashtag çalışması, <b>15.000’den fazla</b> içerik ile Trend Topic listesinde yerini aldı.</h3>
                    
                </div>
            </div>
            <div class="site-preview" style={{ margin: '30px auto 5px'}}>
                <img style={{ width: '95%', margin: '0 auto' }} src={onizleme} />
            </div>
           
            <div className="text-center">
                <span className="small">Başarı Hikayesi</span>
                <h2 style={{ margin: '7px auto 10px', maxWidth: 900 }}><b>Sektörde İlklere İmza Attık!</b> </h2>
                <p style={{ margin: '7px auto 10px', maxWidth: 900 }} >Dijital gelişmeleri, marka gündemine entegre ederek yürüttüğümüz strateji kapsamında hedef kitlemize temas edebileceğimiz yeni iletişim kanalları ile iletişimimizi güncel kıldık.</p>
            </div>
            <div className="text-center customer-content">
                <img style={{ width: '70%', margin: '0 auto' }} src={laptop} />
            </div>
            <div style={{ margin: '100px auto 10px', maxWidth: 800 }} className="text-center">
                <span className="small">Başarı Hikayesi</span>
                <h2 style={{ margin: '10px auto 10px', maxWidth: 800 }}><b>SEO Aksiyonları</b> ile Kullanıcılara İlk Temasımızı Güçlendirdik!</h2>
                <p style={{ margin: '7px auto 50px', maxWidth: 800 }} >SEO danışmanlığı odağında 2020 yılında başladığımız çalışmaların sonucunda organik trafik artışı ve yeni kullanıcı edinimi gibi kazanımlar elde ettik.</p>
                <h2 style={{ fontSize: 25, margin: '50px auto 10px', maxWidth: 800 }}>İyileştirmeler</h2>
                <p><i>Website içeriklerinin ve altyapının SEO uyumlu hale getirilmesi</i></p>
                <p><i>Arama sonuçlarında website görünürlüğünün istenen seviyeye getirilmesi</i></p>
            </div>
            <div className="text-center customer-content">
                <img style={{ width: '70%', margin: '0 auto' }} src={stmseo} />
            </div>
            <div style={{ margin: '10px auto 10px', maxWidth: 800 }} className="text-center">
                <h2 style={{ fontSize: 28, margin: '10px auto 10px', maxWidth: 600 }}><b>1 yılda %22,9</b> oranında organik trafik atışı elde edildi</h2>
                <p style={{ margin: '7px auto 50px', maxWidth: 800 }} >Arama sonuçlarında website görünürlüğünü artırmak üzere on-page ve off-page SEO geliştirmeleri yapılarak organik trafikte 1 yılda %22,9 oranında artış yakalandı.</p>
                <h2 style={{ fontSize: 28, margin: '10px auto 10px', maxWidth: 700 }}>1 yılda organik trafik kanalından elde edilen yeni kullanıcı sayısı <b>%23,08 oranında artırıldı</b></h2>
                <p style={{ margin: '7px auto 50px', maxWidth: 800 }} >Websitenin SEO uyumlu hale getirilmesi için yapılan çalışmaların sonrasında organik trafik kanalı üzerinden erişilen kitle %23,08 oranında artırıldı.</p>
                <h2 style={{ fontSize: 28, margin: '10px auto 10px', maxWidth: 600 }}>1 yılda ortalama oturum süresinde <b>%21,02 artış</b> yakalandı</h2>
                <p style={{ margin: '7px auto 50px', maxWidth: 800 }} >Website altyapısı ve içeriklerin SEO uyumlu hale getirilmesi için yürütülen strateji doğrultusunda organik trafik kanalından gelen ziyaretlerde ortalama oturum süresi %21,02 oranında artırıldı.</p>
            </div>
        </div>


        <OtherCaseStudy />

        <Checkup />
    </Layout >
)

export default ReferancesDetail
